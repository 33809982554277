<template>
  <div class="my-invite-code-container">
    <div class="container__title">邀请亲友</div>
    <div class="code__inner">
      <div class="code__list-container">
        <p v-if="requesting" style="text-align: center">加载中...</p>
        <template v-if="lists">
          <div
            class="code__item"
            ref="codeItems"
            v-for="item in lists"
            :key="item.id"
            :data-id="item.id"
            :data-code="item.code"
          >
            <div class="item__inner">
              <div class="code__text" :data-id="item.id" @click="onClick">
                <p>{{ item.code }}</p>
                <p>邀请</p>
              </div>
              <div class="code__fold">
                <div class="fold__inner">
                  <div class="fold__row">
                    <div class="fold__textarea">
                      <textarea :placeholder="customText" @change="onChange"></textarea>
                      <p class="textarea__fixed">
                        <b>长按识别二维码，成为 {{ user ? user.nickname : "" }} 的亲友！</b>
                        <br />
                        <b class="cheat-link">{{ currentInviteURI }}</b>
                      </p>
                    </div>
                    <div class="user-head-pic">
                      <img :src="user ? user.headimgurl : ''" alt="" />
                    </div>
                  </div>
                  <div class="fold__bt" :data-id="item.id" :data-clipboard-text="currentClipText">
                    <p class="inner">复制发送给亲友</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
    <slogan text="积极互动，获得更多邀请码"></slogan>
    <!-- 底部导航 -->
    <share-bottom></share-bottom>
  </div>
</template>

<script>
import Clipboard from 'clipboard';
import { mapState } from 'vuex';
import slogan from '../common/_slogan.vue';
import shareBottom from '../common/_share-bottom.vue';

export default {
  name: 'view-invite-code-list',
  components: {
    slogan,
    shareBottom,
  },
  data() {
    return {
      lists: null,
      currentShow: 0,
      clipboard: null,
      customText: '重新认识你我，收藏美好时光 🎉 ',
      currentCode: '',
      link:
        process.env.NODE_ENV === 'development'
          ? 'http://localhost:8080/'
          : process.env.VUE_APP_BASE,
      requesting: false,
    };
  },
  computed: {
    ...mapState(['user']),
    currentClipText() {
      if (this.user) {
        return `${this.customText}\n\n长按识别二维码，成为 ${this.user.nickname} 的亲友！\n${this.currentInviteURI}`;
      }
      return '';
    },
    currentInviteURI() {
      return `${this.link}fill-code/${this.currentCode}`;
    },
  },
  watch: {
    $route: {
      handler() {
        this.getList();
      },
      immediate: true,
    },
    currentShow(val, oldVal) {
      const that = this;
      if (oldVal > 0) {
        const close = that.findObj(oldVal);
        close.querySelector('.code__fold').style.height = '0px';
        //
        that.customText = '建设你 建设我 建设我们共同的时光';
        that.currentCode = '';
      }
      if (val > 0) {
        const open = that.findObj(val);
        open.querySelector('.code__fold').style.height = `${
          open.querySelector('.fold__inner').offsetHeight
        }px`;
        that.currentCode = open.dataset.code;
      }
    },
  },
  destroyed() {
    if (this.clipboard) {
      this.clipboard.destroy();
    }
  },
  methods: {
    findObj(id) {
      const items = this.$refs.codeItems;
      return items.find((item) => Number(item.dataset.id) === +id);
    },
    getList() {
      const that = this;
      if (!that.requesting) {
        that.requesting = true;
        that.$request
          .get('api/user/invite/exp-code')
          .then((res) => {
            const { data } = res;
            if (data.list.length > 0) {
              that.lists = data.list;
              that.$nextTick(() => {
                that.clipboard = new Clipboard('.fold__bt');
                that.clipboard.on('success', () => {
                  that.$notify({
                    type: 'success',
                    message: '复制成功',
                    duration: 2000,
                  });
                });
                that.clipboard.on('error', () => {
                  that.$dialog({
                    message: `当前浏览器不支持复制，请手动复制\n${that.currentClipText}`,
                  });
                });
                that.currentShow = that.lists[0].id;
              });
            }
          })
          .finally(() => {
            that.requesting = false;
          });
      }
    },
    onClick(e) {
      const that = this;
      const obj = e.currentTarget;
      const currentId = Number(obj.dataset.id);
      if (that.currentShow === 0) {
        // 打开
        that.currentShow = currentId;
      } else if (that.currentShow === currentId) {
        // 关闭
        that.currentShow = 0;
      } else {
        // 先关闭其他的 再打开现在的
        that.currentShow = currentId;
      }
    },
    // onCopy(e) {
    //   const that = this;
    //   const obj = e.currentTarget;
    //   const currentId = Number(obj.dataset.id);
    //   that
    //     .findObj(currentId)
    //     .querySelector('textarea')
    //     .select();
    // },
    onChange(e) {
      this.customText = e.currentTarget.value;
    },
  },
};
</script>

<style></style>
